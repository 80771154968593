import React from 'react'

const KvikaArrowSmall = () => {
	return (
		<svg
			width='19'
			height='14'
			viewBox='0 0 19 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clip-path='url(#clip0_1519_1375)'>
				<path
					d='M18.1797 7.45312L18.6484 7L18.1797 6.54688L11.8047 0.796875L11.3516 0.367188L10.4922 1.26562L10.9453 1.69531L16.1953 6.375H0.625H0V7.625H0.625H16.1953L10.9453 12.2969L10.4922 12.7266L11.3594 13.6328L11.8125 13.2031L18.1875 7.45312H18.1797Z'
					fill='#B89E74'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1519_1375'>
					<rect width='19' height='14' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default KvikaArrowSmall
