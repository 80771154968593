import styled from "styled-components";
import { theme } from "../../styles/globalStyles"

export const Container = styled.div`
    display: flex;
	flex-direction: column;
	margin: 80px 16px;
    gap: 40px;
    overflow-X: scroll;
    max-width: 1440px;

    @media screen and (min-width: 760px) {
		margin: 80px 40px;
	}

    @media screen and (min-width: 960px) {
        margin: 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 120px;
    }

    @media screen and (min-width: 1680px) {
        margin: 120px auto;
        width: 1440px;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: AdobeCaslonPro, serif;
`

export const Title = styled.div`
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
`

export const Description = styled.div`
    font-size: 22px;
    line-height: 40px;
`

export const Table = styled.div<{$colNum: number, $rowNum: number}>`
    display: grid;
    grid-template-columns: repeat(${props => props.$colNum}, 1fr);
    grid-template-rows: repeat(${props => props.$rowNum}, auto);
`

export const Cell = styled.div<{$col: number, $row: number, $heading: boolean, $bold: boolean, $italic: boolean, $isLink?: boolean }>`
    grid-column: ${props => props.$col};
    grid-row: ${props => props.$row};
    font-family: AkzidenzGroteskPro, serif;
    font-size: 18px;
    display: flex;
    padding: 16px 8px;
    align-items: center;
    border-bottom: ${props => props.$heading ? `solid 2px ${theme.colors.gold400}` : `solid 1px ${theme.colors.gold150}`};
    font-weight: ${props => (props.$bold || props.$heading) ? '500' : '400'};
    font-style: ${props => props.$italic ? 'italic' : 'inherit'};

    ${props =>  props.$isLink && `
        cursor: pointer;
        color: ${theme.colors.gold550};
        text-decoration: underline;
    `}
`