import React from 'react'
import { IBaseSlice } from '../../types/prismic/baseTypes'
import { RichTextField } from '@prismicio/client'
import { PrismicRichText } from '@prismicio/react'
import { Container, Text, Item, Line } from './style'

export interface IHeadingAndParagraph extends IBaseSlice {
	slice_type: 'headingandparagraph'
	items: Array<{
		heading: RichTextField
		text: RichTextField
	}>
}

const HeadingAndParagraph: React.FC<IHeadingAndParagraph> = ({ items }) => {
	return (
		<Container>
			{items.map((item, i) => (
				<>
					<Item key={i}>
						<Text>
							<PrismicRichText field={item.heading} />
						</Text>
						<Text>
							<PrismicRichText field={item.text} />
						</Text>
					</Item>
					{i !== items.length - 1 && <Line />}
				</>
			))}
		</Container>
	)
}

export default HeadingAndParagraph
