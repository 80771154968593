import React from 'react'
import { RichTextField, LinkField, ImageField, TitleField } from '@prismicio/types'
import { PrismicRichText } from '@prismicio/react'

import { IBaseSlice } from '../../types/prismic/baseTypes'

import {
	ItemList,
	Container,
	TextContainer,
	Divider,
} from './style'
import BrandItem from './BrandItem'



export interface IBrandItem {
	brand: ImageField<"Phone" | "Tablet" | "DesktopSm" | "DesktopMd" | "DesktopLg">;
	tile_title: string;
	text: RichTextField;
	link?: LinkField;
	link_text?: string;
	image_on_right?: boolean;
}

export interface IBrand extends IBaseSlice {
	primary: {
		heading: TitleField;
		text: RichTextField;
	};
	items: IBrandItem[];
	slice_type: "brands";
}

type Props = {
	sliceData: IBrand
}

const Brands: React.FC<Props> = ({ sliceData }) => {
	const {
		primary: { heading, text },
		items,
	} = sliceData
	return (
		<Container>
			{(heading || text) && (
				<TextContainer>
					{heading && <PrismicRichText field={heading} />}
					{text && <PrismicRichText field={text} />}
				</TextContainer>
			)}
			<ItemList>
				{items.map((item, index) => (
					<React.Fragment key={`item-${index}`}>
						<Divider />
						<BrandItem {...item}/>
						{index === items.length - 1 && <Divider />}
					</React.Fragment>
				))}
			</ItemList>
		</Container>
	)
}

export default Brands
