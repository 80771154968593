import styled from "styled-components";
import {theme} from "../../styles/globalStyles";
import { media } from "../../utils/style-utils";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 80px 16px;
    max-width: 1440px;

    @media screen and (min-width: 760px) {
        margin: 80px 40px;
	}

    @media screen and (min-width: 960px) {
		margin: 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 120px;
    }

    @media screen and (min-width: 1680px) {
        margin: 120px auto;
    }
}
`

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: ${theme.colors.grey800};

    ${media.phone`
            flex-direction: column;
        `
    }
`

export const Text = styled.div`
    flex: 1;

    * {
        font-family: AdobeCaslonPro;
    }

    p, div {
        font-size: 22px;
        line-height: 40px;
        font-weight: 400; 
        margin: 0;
        color: ${theme.colors.grey800};
        
        ${media.phone`
            font-size: 18px;
            line-height: 32.4px;    
        `}
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-size: 28px;
        line-height: 42px;
        color: ${theme.colors.greyBlack};
        margin: 0 80px 0 0;

        ${media.phone`
                font-size: 24px;
                line-height: 36px;
                margin: 0 0 16px 0;
            `
        }
    }

`

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.gold150};
    margin: 80px 0;

    ${media.phone`
        margin: 40px 0;
    `}
`
