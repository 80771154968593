import React from 'react'
import { IBaseSlice, IPrismicLink } from '../../types/prismic/baseTypes'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import {
	Container,
	Card,
	CardContainer,
	Description,
	TextContent,
	Title,
	StyledImage,
	ImageContainer,
	StyledLink,
} from './style'
import { PrismicNextImage } from '@prismicio/next'
import { ImageFieldImage } from '@prismicio/client'

export interface ISmallCard extends IBaseSlice {
	slice_type: 'small_card'
	items: Array<{
		card_description: string
		card_title: string
		image: typeof PrismicNextImage
		link: IPrismicLink
	}>
}

type SmallCardProps = {
	sliceData: ISmallCard
}

const SmallCard: React.FC<SmallCardProps> = ({ sliceData }) => {
	const { pages } = usePrismicRoutingContext()
	
	return (
		<Container>
			<CardContainer>
				{sliceData.items.map((item) => (
					<StyledLink
						href={resolvePrismicLink(item.link, pages) ?? '#'}
						key={item.card_title}
					>
						<Card>
							<ImageContainer>
								<StyledImage
									field={item.image as ImageFieldImage}
									width={0}
									height={0}
									sizes='100vw'
									id='main'
								/>
								<StyledImage
									field={item.image?.['medium'] as ImageFieldImage ?? item.image as ImageFieldImage}
									width={0}
									height={0}
									sizes='100vw'
									id='medium'
								/>
								<StyledImage
									field={item.image?.['small'] as ImageFieldImage ?? item.image as ImageFieldImage}
									width={0}
									height={0}
									sizes='100vw'
									id='small'
								/>
							</ImageContainer>
							<TextContent>
								<Title>{item.card_title}</Title>
								<Description>{item.card_description}</Description>
							</TextContent>
						</Card>
					</StyledLink>
				))}
			</CardContainer>
		</Container>
	)
}

export default SmallCard
