import styled from "styled-components"
import {theme} from "../../styles/globalStyles"

export const MainContainer = styled.div<{$background?: string}>`
    display: flex;
	flex-direction: column;
	margin: 80px 16px;
    gap: 40px;
    font-family: AdobeCaslonPro, serif;

    @media screen and (min-width: 760px) {
		margin: 80px 40px;
	}

    @media screen and (min-width: 960px) {
        margin: 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 120px;
    }

    @media screen and (min-width: 1680px) {
        width: 1440px;
        margin: 120px auto;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const Header = styled.div`
    font-size: 28px;
    line-height: 44px;
    font-weight: 600;
    color: ${theme.colors.greyBlack};

    @media screen and (min-width: 960px) {
        font-size: 32px;
    }
`

export const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px 8px;
    gap: 8px;
    color: ${theme.colors.grey800};
    justify-content: center;
    cursor: pointer;
    border-bottom: solid 1px ${theme.colors.gold150};

    &:first-child {
        border-top: solid 1px ${theme.colors.gold150};
    }

    &:hover {
        color: ${theme.colors.grey600};

        > div > svg > path {
            fill: ${theme.colors.gold300};
        }
    }
`

export const Question = styled.div`
    font-size: 22px;
    line-height: 40px;
`

export const Answer = styled.div<{$show: boolean}>`
    display: ${props => props.$show? "inherit" : "none"};
    flex-direction: column;

    * {
        font-size: 16px;
        line-height: 32px;
    }

    @media screen and (min-width: 960px) {
        * {
            font-size: 18px;
        }
    }
`

export const ChevronContainer = styled.div<{$show: boolean}>`
    display: flex;
    position: absolute;
    right: 0;
    top: 25px;
    rotate: ${props => props.$show ? "270deg" : "90deg"};
`