import React from "react";
import type { ImageField } from "@prismicio/types";

import { Image, Picture } from "./style";

interface ResponsiveBrandImageProps {
  field: ImageField<"Phone" | "Tablet" | "DesktopSm" | "DesktopMd" | "DesktopLg">;
  className?: string;
}

const ResponsiveBrandImage: React.FC<ResponsiveBrandImageProps> = ({ field, className }) => {
  const { url, alt } = field;
  // If any responsive sizes are available, use them.
  // Note: These keys are added directly to the image object per the type.
  if (
    field.Phone ||
    field.Tablet ||
    field.DesktopSm ||
    field.DesktopMd ||
    field.DesktopLg
  ) {
    return (
      <Picture className={className}>
        {field.DesktopLg && (
          <source media="(min-width: 1200px)" srcSet={field.DesktopLg.url || ""} />
        )}
        {field.DesktopMd && (
          <source media="(min-width: 992px)" srcSet={field.DesktopMd.url || ""} />
        )}
        {field.DesktopSm && (
          <source media="(min-width: 768px)" srcSet={field.DesktopSm.url || ""} />
        )}
        {field.Tablet && (
          <source media="(min-width: 576px)" srcSet={field.Tablet.url || ""} />
        )}
        {field.Phone && (
          <source media="(max-width: 575px)" srcSet={field.Phone.url || ""} />
        )}
        <Image src={url || ""} alt={alt || ""} />
      </Picture>
    );
  }
  return <Image src={url || ""} alt={alt || ""} className={className} />;
};

export default ResponsiveBrandImage;