import React, { useEffect, useState } from 'react'
import { IBaseSlice } from '../../types/prismic/baseTypes'
import { Container, Header, Question, Answer, MainContainer, QuestionContainer, ChevronContainer } from './styles'
import Chevron from '../icons/Chevron'
import { RichTextField } from '@prismicio/types'
import { PrismicRichText } from '@prismicio/react'

interface IQuestion {
	question: string
	answer: RichTextField
}

export interface IFAQ extends IBaseSlice {
	primary: {
		header: string
	}
	items: Array<IQuestion>
	slice_type: 'faq'
}

interface IQuestionForm extends IQuestion {
	id: number
	isActive: boolean;
}

const FAQ: React.FC<IFAQ> = ({ primary, items }) => {
	const [questions, setQuestions] = useState<Array<IQuestionForm>>([]);

	useEffect(() => {
		const questionGroup: Array<IQuestionForm> = items.map((item, i)=> ({...item, isActive: false, id: i}));
		setQuestions(questionGroup);	
	}, []);

	const toggleAnswer = (id: number) => {
		const updatedArr = questions.map((question) => {
			return question.id === id ? {...question, isActive: !question.isActive} : question;
		})

		setQuestions(updatedArr);
	}

	return (
		<MainContainer>
			<Header>{primary.header}</Header>
			<Container>
				{questions.map((item) => (
					<QuestionContainer key={item.question} onClick={() =>toggleAnswer(item.id)}>						
						<Question>{item.question}</Question>
						<Answer $show={item.isActive}>
							<PrismicRichText field={item.answer} />
						</Answer>
						<ChevronContainer $show={item.isActive}>
							<Chevron />
						</ChevronContainer>
					</QuestionContainer>
				))}
			</Container>
		</MainContainer>
	)
}

export default FAQ
