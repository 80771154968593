import React from 'react'
import { IBaseSlice } from '../../../types/prismic/baseTypes'
import {
	Container,
	ArticlesContainer,
	Article,
	MainTextContainer,
	ArticleTitle,
	LinkLabel,
	Title,
	DateLabel,
	StyledLink
} from './style'
import Link from 'next/link'
import dayjs from 'dayjs'
import icelandicLocale from 'dayjs/locale/is'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import useExchangeNewsData from '../../../hooks/data/use-stock-exchange-news'
import { useRouter } from 'next/router'

dayjs().format()
dayjs.extend(customParseFormat)
dayjs.locale(icelandicLocale)

export interface ISXNews extends IBaseSlice {
	primary: {
		maintitle: string
		newslinklabel: string
		numberofarticles: number
	}
	slice_type: 'lateststockexchangenews'
}

const SXNews: React.FC<ISXNews> = ({
	primary: { maintitle, newslinklabel, numberofarticles },
}) => {
	const router = useRouter();
	const date = new Date()
	date.setMonth(date.getMonth() - 3)

	const { data, isFetched, isStale, refetch } = useExchangeNewsData(
		'KVIKA',
		date.toISOString().substring(0, 10),
		router.locale ?? "is",
		{
			cacheTime: 2 * 60 * 1000,
			staleTime: 2 * 60 * 1001,
		}
	)
	
	if (isStale) {
		refetch()
	}

	return (
		<Container>
			<MainTextContainer>
				<Title>{maintitle}</Title>
				<StyledLink href={'/#'}>
					<LinkLabel>{newslinklabel}</LinkLabel>
				</StyledLink>
			</MainTextContainer>
			<ArticlesContainer>
				{isFetched &&
					data &&
					data.slice(0, numberofarticles).map((article) => (
						<Article key={article.title}>
							<DateLabel>
								{dayjs(article.date).format('DD. MMMM YYYY')}
							</DateLabel>
							<StyledLink href={article.url}>
								<ArticleTitle>{article.title}</ArticleTitle>
							</StyledLink>
						</Article>
					))}
			</ArticlesContainer>
		</Container>
	)
}

export default SXNews
