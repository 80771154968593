import styled from 'styled-components'
import { theme } from '../../styles/globalStyles'
import Image from 'next/image'
import Link from 'next/link'
import { media } from '../../utils/style-utils'

export const CardContainer = styled.div`
	display: flex;
	padding: 80px 16px;
	justify-content: center;

	@media screen and (min-width: 760px) {
		padding: 120px 40px;
	}

	@media screen and (min-width: 960px) {
		padding: 120px 80px;
	}

	@media screen and (min-width: 1280px) {
		padding: 120px;
	}
`

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1440px;

	@media screen and (min-width: 760px) {
		display: grid;
		grid-template-columns: 40% 60%;
	}
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px;
	gap: 40px;
	background-color: ${theme.colors.gold50};

	@media screen and (min-width: 740px) {
		justify-content: center;
		padding: 40px 24px;
		width: 100%;
	}

	@media screen and (min-width: 1280px) {
		padding: 56px 80px;
	}
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	* {
		font-family: AdobeCaslonPro, serif;
		margin: 0;
		padding: 0;
		font-size: 18px;
	}
`

export const Title = styled.div`
	font-size: 32px;
	line-height: 40px;
	color: var(--grey800);

	@media screen and (max-width: ${theme.breakpoints.tabletMax}) {
		font-size: 24px;
		line-height: 28.8px;
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;
	font-family: AkzidenzGroteskPro, serif;

	${media.phone`
		flex-direction: column;
	`}
`

export const DocumentLink = styled(Link)`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	font-size: 16px;
`

export const ImageContainer = styled.div`
	width: 100%;
`

export const StyledImage = styled(Image)`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px ${theme.colors.gold250};
	border-radius: 4px;
	color: ${theme.colors.gold550};
	padding: 16px 32px;
	background: none;
	cursor: pointer;
	width: max-content;
	font-size: 1rem;
	font-weight: 500;

	&:hover {
		border: solid 1px ${theme.colors.gold550};
	}
`
