import useLocalization from '../../../hooks/utils/use-localization'
import { IPage } from '../../../types/prismic/pages/generalPage'
import { IPrismicImage, IPrismicLink } from '../../../types/prismic/baseTypes'
import {
	ArticlesContainer,
	Container,
	Article,
	StyledImage,
	ImageContainer,
	Title,
	TextContainer,
	AllNews,
	NewsTitle,
	MainTextContainer,
	LinkContainer,
	PublishedDate,
} from './style'
import Link from 'next/link'
import dayjs from 'dayjs'
import icelandicLocale from 'dayjs/locale/is'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useRouter } from 'next/router'
import KvikaArrowSmall from '../../icons/KvikaArrowSmall'

dayjs().format()
dayjs.extend(customParseFormat)
dayjs.locale(icelandicLocale)

export interface INewsArticles {
	primary: {
		see_all_news_link: IPrismicLink
		see_all_news_text?: string
		number_of_articles: number
		section_color?: IPage.Section['section_color']
		section_title?: IPage.Section['section_title']
	}
	newsArticlesPreview: {
		title: string
		short_description: string
		published_date: Date
		href: string
		preview_image: IPrismicImage
	}[]
	slice_label?: string
	slice_type: 'latest_news_articles'
}

interface IProps {
	sliceData: INewsArticles
}

const defaultImage =
	'https://images.prismic.io/kvika/0c961be9-9a2e-4a80-be79-9bfb64ca018f_media_katrinartun.jpg'

const NewsSummaryList = ({ sliceData }: IProps) => {
	const { locale } = useLocalization()
	const router = useRouter()

	return (
		<Container>
			<MainTextContainer>
				<NewsTitle>
					{sliceData.primary.section_title ?? 'Fréttir og tilkynningar'}
				</NewsTitle>
				<LinkContainer>
					<Link href={locale === 'is' ? '/frettir' : '/news'}>
						<AllNews>
							{sliceData.primary?.see_all_news_text ?? 'Allar fréttir'}
						</AllNews>
					</Link>
					<KvikaArrowSmall />
				</LinkContainer>
			</MainTextContainer>
			<ArticlesContainer>
				{sliceData.newsArticlesPreview.map((article) => (
					<Article
						key={article.title}
						onClick={() => router.push(article.href)}
					>
						<ImageContainer>
							<StyledImage
								src={article.preview_image.url ?? defaultImage}
								width={0}
								height={0}
								sizes='100vw'
								alt={article.title}
							/>
						</ImageContainer>
						<TextContainer>
							<PublishedDate>
								{dayjs(article.published_date).format('DD. MMMM YYYY')}
							</PublishedDate>
							<Title>{article.title}</Title>
						</TextContainer>
					</Article>
				))}
			</ArticlesContainer>
		</Container>
	)
}

export default NewsSummaryList
