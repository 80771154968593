import React from 'react'
import { IBaseSlice, IPrismicLink } from '../../types/prismic/baseTypes'
import {
	Container,
	Description,
	TextContainer,
	Title,
	Table,
	Cell,
} from './style'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import { useRouter } from 'next/router'

interface ICell {
	column_heading: boolean
	column_index: number
	row_index: number
	label?: string
	link: IPrismicLink
	bold: boolean
	italic: boolean
}

export interface ITableV2 extends IBaseSlice {
	primary: {
		main_title: string
		description_text?: string
	}
	items: Array<ICell>
	slice_type: 'tablev2'
}

const TableV2: React.FC<ITableV2> = ({ primary, items }) => {
	const { pages } = usePrismicRoutingContext()
	const router = useRouter()
	const lastCell = items.sort((a, b) => b.column_index - a.column_index)[0];

	const handleClick = (link: IPrismicLink) => {
		if (link?.link_type) {
			const res = resolvePrismicLink(link, pages)
			res && router.push(res)
		}
	}

	if(!lastCell) {
		return null;
	}

	return (
		<Container>
			<TextContainer>
				<Title>{primary.main_title}</Title>
				<Description>{primary.description_text}</Description>
			</TextContainer>
			<Table $colNum={lastCell.column_index} $rowNum={lastCell.row_index}>
				{items.map((cell) => (
					<Cell
						key={cell.column_index+cell.row_index}
						$col={cell.column_index}
						$row={cell.row_index}
						$bold={cell.bold}
						$italic={cell.italic}
						$heading={cell.column_heading}
						onClick={() => handleClick(cell.link)}
						//@ts-ignore
						$isLink={cell.link.uid || cell.link.url}
					>
						{cell.label && cell.label}
					</Cell>
				))}
			</Table>
		</Container>
	)
}

export default TableV2
