import styled from "styled-components";
import {theme} from "../../../styles/globalStyles";
import Link from "next/link";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 80px 16px 120px 16px;
    gap: 40px;
    max-width: 1440px;
    
    @media screen and (min-width: 760px) {
        margin: 80px 40px 120px 40px;
        gap: 56px;
    }

    @media screen and (min-width: 960px) {
        margin: 80px 80px 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 80px 120px 120px 120px;
    }

    @media screen and (min-width: 1920px) {
        margin: 80px auto 120px auto;
    }
`

export const ArticlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 56px;

    @media screen and (min-width: 740px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 40px 24px;
    }

    @media screen and (min-width: 1512px) {
        gap: 56px; 
    }
`

export const Article = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: 740px) {
        flex: 1 0 30%;
    }

    @media screen and (min-width: 1280px) {
        flex: 1;
    }
`

export const ArticleTitle = styled.div`
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    font-family: AdobeCaslonPro, serif;
    color: ${theme.colors.grey800};
`

export const DateLabel  = styled.div`
    font-family: AkzidenzGroteskPro;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: ${theme.colors.gold400};
`

export const MainTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const Title = styled.div`
    font-family: AdobeCaslonPro, serif;
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    color: ${theme.colors.grey600};
`

export const LinkLabel = styled.div`
    font-family: AkzidenzGroteskPro;
    font-size: 14px;
    line-height: 14px;
    color: ${theme.colors.gold400};
    font-weight: 500;
`

export const StyledLink = styled(Link)`
    transition: all 0.5s ease;
    text-decoration: text-decoration rgba(255, 255, 255, 0);

    &:hover {
        text-decoration: underline ${theme.colors.gold400};
        transition: text-decoration 0.5s ease;
    }
`






