import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import KvikaArrow from '../icons/KvikaArrow'

import {
	ImageArea,
	ImageWrapper,
	ItemRow,
	LinkContainer,
	LinkText,
	TextArea,
} from './style'
import { IBrandItem } from './Brands'
import ResponsiveBrandImage from './ResponsiveBrandImage'

interface IBrandItemProps extends IBrandItem {}

const BrandItem: React.FC<IBrandItemProps> = ({
	brand,
	tile_title,
	text,
	link,
	link_text,
	image_on_right,
}) => {
	return (
		<ItemRow imageOnRight={image_on_right}>
			<ImageArea>
				<ImageWrapper>
					<ResponsiveBrandImage field={brand} />
				</ImageWrapper>
			</ImageArea>
			<TextArea imageOnRight={image_on_right}>
				{tile_title && <h2>{tile_title}</h2>}
				<PrismicRichText field={text} />
				{link && link_text && (
					<LinkContainer>
						<LinkText field={link}>
							{link_text}
							<KvikaArrow />
						</LinkText>
					</LinkContainer>
				)}
			</TextArea>
		</ItemRow>
	)
}

export default BrandItem
