import React from 'react'

const Chevron = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.0078 10L13.5664 10.4414L7.31641 16.6914L6.875 17.1328L5.99219 16.25L6.43359 15.8086L12.2422 10L6.43359 4.19141L5.99219 3.75L6.875 2.86719L7.31641 3.30859L13.5664 9.55859L14.0078 10Z" fill="#B89E74"/>
    </svg>

  )
}

export default Chevron