import styled, { css } from 'styled-components'
import { PrismicLink } from '@prismicio/react'

import { theme } from '../../styles/globalStyles'
import { media, mediaNext } from '../../utils/style-utils'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 80px 16px;
	gap: 40px;
	max-width: 1440px;

	${mediaNext.tablet`
		margin: 80px 40px;
	`}

	${mediaNext.desktopSm`
		margin: 120px 80px;
	`}

	${mediaNext.desktopMd`
		margin: 120px;
	`}

	${mediaNext.desktopLg`
		margin: 120px auto;
	`}

`

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;

	* {
		font-family: AdobeCaslonPro, serif;
		color: ${theme.colors.grey800};
		font-size: 18px;
		margin: 0;
		padding: 0;
		text-align: center;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 40px;
		line-height: 60px;
		color: ${theme.colors.goldenGrey600};

		${media.phone`
            font-size: 32px;
        `}
	}
`

export const ItemList = styled.div`
	display: flex;
	flex-direction: column;
`

export const ItemRow = styled.div<{ imageOnRight?: boolean }>`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 120px;
	height: 424px;
	overflow: hidden;

	${({ imageOnRight }) =>
		imageOnRight &&
		css`
			& > :first-child {
				order: 2;
			}
			& > :last-child {
				order: 1;
			}
		`}

	${mediaNext.phone`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		align-self: stretch;
		gap: 0px;
		height: auto;

		& > :first-child {
			order: 1;
		}
		& > :last-child {
			order: 2;
		}
	`}
	${mediaNext.tablet`
		gap: 40px;
	`}
`

export const ImageArea = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	${mediaNext.phone`
			margin-block: 40px;
	`}
`

export const TextArea = styled.div<{ imageOnRight?: boolean }>`
	color: var(--greys-grey-800-default, var(--Grey-Grey-800, #3b3b3b));
	padding: ${p => p.imageOnRight ? '0 0 0 80px' : '0 80px 0 0' };

	${mediaNext.phone`
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: unset;
	`}

	p {
		font-family: AdobeCaslonPro, serif;
		font-size: 1.375rem;
		font-weight: normal;
		line-height: 2rem;
	}

	h2 {
		color: ${theme.colors.goldenGrey600};
		font-family: AdobeCaslonPro, serif;
		font-size: 2.5rem;
		font-weight: 400;
		line-height: 3rem;
	}
`

export const Divider = styled.div`
	margin: 24px 0px;
	height: 1px;
	background-color: ${theme.colors.gold150};
`

export const LinkContainer = styled.div`
	svg,
	path {
		transition: fill 500ms, transform 500ms;
	}

	&:hover {
		path {
			fill: ${theme.colors.gold400};
		}
		svg {
			transform: translateX(4px);
			transition: 500ms;
		}
	}
`

export const LinkText = styled(PrismicLink)`
	display: flex;
	align-items: center;
	gap: 8px;
	color: ${theme.colors.gold300};
	text-align: center;

	font-family: AkzidenzGroteskPro, sans-serif;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1rem;
`

export const Picture = styled.picture`
	display: block;
	width: 100%;
	height: 100%;
`

export const Image = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
`