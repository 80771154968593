import { useQuery } from 'react-query'

import { IStockExchangeResponseData, IStockExchangeSingleData } from '../../modules/investor-information/StockExchange/StockExchangeNews'

const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000
const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000

export const fetchStockExchangeNews = async (
	ticker: string,
	date: string,
	lang: string
): Promise<Array<IStockExchangeSingleData>> => {
	return fetch(
		`/api/news/stock-exchange/?ticker=${ticker}&from_date=${date}&lang=${lang}`
	).then((res) => {
		if (res.status !== 200) throw 'Generic error'
		return res.json()
	})
}

export default function useExchangeNewsData(
	ticker: string,
	date: string,
	lang: string,
	options?: { staleTime?: number; cacheTime?: number } | undefined
) {
	return useQuery(
		'exchangeData',
		() => fetchStockExchangeNews(ticker, date, lang),
		{
			staleTime: options?.staleTime || THIRTY_MINUTES_IN_MS,
			cacheTime: options?.cacheTime || FIFTEEN_MINUTES_IN_MS,
		}
	)
}
