import React from 'react'
import { IBaseSlice, IPrismicLink } from '../../types/prismic/baseTypes'
import {
	Container,
	Description,
	EventContainer,
	TextContainer,
	Title,
	Cell,
	LinkContainer,
    BottomText,
} from './style'
import ClippedRectangle from '../../../public/svg/ClippedRectangle.svg'
import Image from 'next/image'
import Chevron from '../../../public/svg/Chevron.svg'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import icelandicLocale from 'dayjs/locale/is'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'

dayjs.extend(customParseFormat)
dayjs.locale(icelandicLocale)

export interface IEventCalendar extends IBaseSlice {
	primary: {
		title_text: string
		description_text?: string
		background_color: string
		bottom_text?: string
	}
	items: Array<{
		date_start: string
		date_end?: string
		text: string
		link: IPrismicLink
		link_label: string
		bold: boolean
		italic: boolean
	}>
	slice_type: 'events_calendar'
}

const EventCalendar: React.FC<IEventCalendar> = ({ primary, items }) => {
	const {pages} = usePrismicRoutingContext();
	
	return (
		<Container $background={primary.background_color}>
			<TextContainer>
				<Title>{primary.title_text}</Title>
				{primary.description_text && <Description>{primary.description_text}</Description>}
				<Image alt='clipped-rectangle-svg' src={ClippedRectangle} />
			</TextContainer>
			<EventContainer>
				{items.map((event) => (
					<>
						<Cell $bold={event.bold} $italic={event.italic}>
							{event.date_end
								? `${event.date_start.substring(0,4) === event.date_end.substring(0,4) ? dayjs(event.date_start).format('DD. MMMM ') : dayjs(event.date_start).format('DD. MMMM YYYY')} - ${dayjs(
										event.date_end
								  ).format('DD. MMMM YYYY')}`
								: dayjs(event.date_start).format('DD. MMMM YYYY')}
						</Cell>
						<Cell $bold={event.bold} $italic={event.italic}>
							{event.text}
						</Cell>
						<Cell>
							{event.link && event.link_label && (
								<LinkContainer href={resolvePrismicLink(event.link, pages) ?? "#"}>
									<span>{event.link_label}</span>
									<Image alt='chevron' src={Chevron} />
								</LinkContainer>
							)}
						</Cell>
					</>
				))}
                {primary.bottom_text && 
                    <BottomText>
                        {primary.bottom_text}
                    </BottomText>
                }
			</EventContainer>
		</Container>
	)
}

export default EventCalendar
