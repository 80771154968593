import React from 'react'
import { IBaseSlice } from '../../types/prismic/baseTypes'
import { IPrismicLink } from '../../types/prismic/baseTypes'
import { Card, Item, Text, Button, Container } from './style'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { useRouter } from 'next/router'
import useLocalization from '../../hooks/utils/use-localization'
import { PrismicRichText } from '@prismicio/react'
import { RichTextField } from '@prismicio/types'

export interface ICta extends IBaseSlice {
	primary: {
		maintext: RichTextField
		text: RichTextField
		link?: IPrismicLink
		buttontext?: string
	}
	slice_type: 'cta'
}

const Cta: React.FC<ICta> = ({
	primary: { link, maintext, text, buttontext },
}) => {
	const { pages } = usePrismicRoutingContext()
	const router = useRouter()
	const { translate } = useLocalization()

	const handleClick = () => {
		if (link?.link_type) {
			const res = resolvePrismicLink(link, pages)
			res && router.push(res)
		}
	}

	// If no desc. text provided then CTA is considered as a Highlight slice
	if (!Array.isArray(text) || !text.length) {
		return (
			<Container $highlight>
				<Card>
					<Item $highlight>
						<Text $main>
							<PrismicRichText field={maintext} />
						</Text>
					</Item>
				</Card>
			</Container>
		)
	}

	return (
		<Container>
			<Card>
				<Item>
					<Text $main>
						<PrismicRichText field={maintext} />
					</Text>
				</Item>
				<Item>
					<Text>
						<PrismicRichText field={text} />
					</Text>
					{link?.link_type !== 'Any' && (
						<Button onClick={handleClick}>
							{buttontext ?? translate('contactForm.kvika.labels.getInTouch')}
						</Button>
					)}
				</Item>
			</Card>
		</Container>
	)
}

export default Cta
