import styled from "styled-components"
import {theme} from "../../styles/globalStyles"
import Link from "next/link"

export const Container = styled.div<{$background?: string}>`
    display: flex;
	flex-direction: column;
	margin: 80px 16px;
    gap: 80px;
    background-color: ${({$background}) => $background ? theme.colors[$background.toLowerCase()] : "inherit"};
    overflow-X: scroll;
    max-width: 1440px;

    @media screen and (min-width: 760px) {
		margin: 120px 40px;
	}

    @media screen and (min-width: 960px) {
        margin: 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        flex-direction: row;
        margin: 160px 120px;
        gap: 120px;
    }

    @media screen and (min-width: 1680px) {
        margin: 160px auto;
        width: 1440px;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
    font-family: AdobeCaslonPro, serif;
    min-width: 240px;
    max-width: 400px;
`

export const Title = styled.div`
    color: ${theme.colors.greyBlack};
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
`

export const Description = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
`

export const EventContainer = styled.div`
    display: grid;
    flex: 1;
    grid-template-columns: 280px auto 160px;
    font-family: AkzidenzGroteskPro, serif;

    > .cell:nth-last-child(-n + 4) {
        border-bottom: solid 1px ${theme.colors.lightBeige};
    }
`

export const Cell = styled.div.attrs({
    className: "cell"
})<{$bold?: boolean, $italic?: boolean}>`
    display: flex;
    align-items: center;
    padding: 16px;
    border-top: solid 1px ${theme.colors.lightBeige};

    font-weight: ${({$bold}) => $bold ? '700' : '400'};
    font-style: ${({$italic}) => $italic ? 'italic' : 'inherit'};
`

export const LinkContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    color: ${theme.colors.gold400};
    font-size: 14px;
    line-height: 16px;
`

export const BottomText = styled.div`
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 14px;
`