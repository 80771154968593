import styled from 'styled-components'
import { theme } from '../../styles/globalStyles'
import { media } from '../../utils/style-utils'

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	max-width: 1440px;

	@media screen and (min-width: 760px) {
		flex-direction: row;
	}

	@media screen and (min-width: 960px) {
		gap: 120px;
	}
`

export const Container = styled.div<{ $highlight?: boolean }>`
	display: flex;
	background-color: ${theme.colors.grey800};
	padding: 80px 16px;

	@media screen and (min-width: 760px) {
		padding: 80px 40px;
	}

	@media screen and (min-width: 960px) {
		padding: 120px 80px;
	}

	@media screen and (min-width: 1280px) {
		padding: 120px;
	}

	@media screen and (min-width: 1680px) {
		justify-content: center;
	}

    ${({$highlight}) => $highlight && `
		justify-content: center;
		align-items: center;
    `}
`

export const Item = styled.div<{ $highlight?: boolean }>`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 40px;
	color: ${theme.colors.gold150};

	${({ $highlight }) =>
		$highlight && `
        text-align: center;
		max-width: 800px;
		
        * {
            font-size: 22px !important;
            line-height: 39.6px !important;
        }
    `}
`

export const Text = styled.div<{ $main?: boolean }>`
	* {
		font-family: AdobeCaslonPro, serif;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: 32.4px;
	}

	${({ $main }) =>
		$main &&
		`
        * {
            font-size: 32px;
            line-height: 48px;
        }
    `}

	${media.phone`
        * {
            font-size: 18px;
            line-height: 32.4px; 
        }

        ${({ $main }) =>
					$main &&
					`
            * {
                font-size: 32px;
                line-height: 48px;
            }
        `}
    `}
`

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px ${theme.colors.gold250};
	border-radius: 4px;
	color: ${theme.colors.gold250};
	padding: 16px 32px;
	background: none;
	cursor: pointer;
	width: max-content;
	font-size: 1rem;

	&:hover {
		color: ${theme.colors.gold150};
		border: solid 1px ${theme.colors.gold150};
	}
`
