import Image from "next/image";
import styled from "styled-components";
import {theme} from "../../../styles/globalStyles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 80px 16px 120px 16px;
    gap: 40px;
    max-width: 1440px;

    @media screen and (min-width: 760px) {
        margin: 80px 40px 120px 40px;
        gap: 56px;
    }

    @media screen and (min-width: 960px) {
        margin: 80px 80px 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 80px 120px 120px 120px;
    }

    @media screen and (min-width: 1680px) {
        margin: 80px auto 120px auto;
    }
`

export const ArticlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 56px;

    @media screen and (min-width: 740px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 56px 24px;
    }

    @media screen and (min-width: 1280px) {
        gap: 56px;
    }

    @media screen and (min-width: 1680px) {
        gap: 80px;
    }
`

export const Article = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    cursor: pointer;
    min-width: 280px;

    @media screen and (min-width: 740px) {
        flex: 1 0 32%;
    }

    @media screen and (min-width: 1280px) {
        flex: 1;
    }


    &:hover {
        > div > img {
            transform: scale(1.05);
			transition: all 0.5s ease; 
        }

        > div > div:last-child {
            text-decoration: underline ${theme.colors.gold400};
            transition: text-decoration 0.5s ease;
        }
    }    
`

export const ImageContainer = styled.div`
    height: 202px;
    overflow: hidden;
`

export const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;

    transform: scale(1.00);
    transition: all 0.5s ease; 
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const Title = styled.div`
    font-size: 22px;
    line-height: 32px;
    font-family: AdobeCaslonPro, serif;
    color: ${theme.colors.grey800};
    font-weight: 400;

    text-decoration: underline rgba(255, 255, 255, 0);
    transition: text-decoration 0.5s ease;

    &:hover {
        text-decoration: underline ${theme.colors.gold400};
        transition: text-decoration 0.5s ease;
    }
`

export const Description = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: AkzidenzGroteskPro;
    font-weight: 400;
    color: ${theme.colors.grey600};
`

export const ReadNews = styled.div`
    font-family: AkzidenzGroteskPro;
    color: ${theme.colors.gold400};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 20px;
`

export const MainTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const NewsTitle = styled.div`
    font-weight: 400;
    font-size: 32px;
    line-height: 38.4px;
    color: ${theme.colors.goldenGrey600};
    font-family: AdobeCaslonPro, serif;

    @media screen and (min-width: 740px) {
        font-size: 40px;
        line-height: 48px;
    }
`

export const AllNews = styled.div`
    color: ${theme.colors.gold400};
    font-family: AkzidenzGroteskPro;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
`

export const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: max-content;
    cursor: pointer;

    &:hover {
        * { 
            color: ${theme.colors.gold550};
            text-decoration: none;
        }

        > svg > g > path {
            fill: ${theme.colors.gold550}; !important; 
        }
    }
`

export const PublishedDate = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${theme.colors.gold550};
`



