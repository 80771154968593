import styled from 'styled-components'
import { theme } from '../../styles/globalStyles'
import { PrismicNextImage } from '@prismicio/next'

export const Container =  styled.div`
    display: flex;
	padding: 80px 16px 40px 16px;

    @media screen and (min-width: 760px) {
        padding: 80px 40px 40px 40px;
	}

    @media screen and (min-width: 960px) {
		padding: 120px 80px 80px 80px;
    }

    @media screen and (min-width: 1280px) {
        padding: 120px;
    }

    @media screen and (min-width: 1680px) {
        padding: 120px 0;
		justify-content: center;
    }
`   

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	max-width: 1440px;

	@media screen and (min-width: 760px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 40px 24px;
		width: 100%;
		
		& > a:nth-last-child(1):nth-child(odd) {
			grid-column: 1/-1;
		}
	}

	@media screen and (min-width: 1280px) {
		display: flex;
		flex-direction: row;
		gap: 24px;
	}

	@media screen and (min-width: 1512px) {
		gap: 56px;
	}
`

export const Card = styled.div`
	font-family: AdobeCaslonPro, serif;
	display: flex;
	flex-direction: column;
	border-bottom: solid 1px ${theme.colors.grey100};
	transition: border-color 0.5s ease;
	&:hover {
		border-bottom: solid 2px ${theme.colors.gold150};
	}

	&:hover > div {
		> img {
			transform: scale(1.05);
			transition: all 0.5s ease;
		}

		> div:first-child {
			text-decoration: underline;
			text-decoration-color: ${theme.colors.gold400};
		}
	}

	> div {
	 	> img {
			transform: scale(1);
			transition: all 0.5s ease;
		}

		> div:first-child {
		 	text-decoration: underline;
			text-decoration-color: rgba(255, 255, 255, 0);
			transition: all 0.5s ease;
		}
	}
`

export const TextContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 40px 0;
`

export const Title = styled.div`
	font-size: 28px;
	font-weight: 400;
	line-height: 42px;
	color: ${theme.colors.grey600} !important;
`

export const Description = styled.div`
	font-size: 18px;
	line-height: 32.4px;
	color: ${theme.colors.grey600} !important;
`

export const ImageContainer = styled.div`
	height: 264px;
	overflow: hidden;

    #small {
        display: inherit;
    }

    #main, #medium {
        display: none;
    }

	@media screen and (min-width: 960px) {
        #main, #small {
            display: none;
        }

		#medium {
			display: block;
		}
	}

	@media screen and (min-width: 1280px) {
        #medium, #small {
            display: none;
        }
		#main {
			display: block;
		}
	}
`
export const StyledImage = styled(PrismicNextImage)`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const StyledLink = styled.a`
	&:link {
		text-decoration: none;
	}
`
